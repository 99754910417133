import * as S from "components/common/Banner/Overlay.style";

const OverlayMobile = () => (
  <S.Overlay>
    <S.TextBanner variant="headline-01" color="white">
      <S.MainInfo>CNP Seguros Holding Brasil - CSH</S.MainInfo>
      <S.SecondaryInfo>Há mais de 20 anos cuidando dos</S.SecondaryInfo>
      <S.SecondaryInfo>brasileiros.</S.SecondaryInfo>
    </S.TextBanner>
    <S.BannerIndicator />
  </S.Overlay>
);
export default OverlayMobile;
