import styled from "styled-components/macro";

export const Banner = styled.div({
  width: "100%",
  display: "grid",
});

export const Background = styled.img(({ theme, width, height, isMobile }) => ({
  minHeight: "226px",
  objectPosition: "bottom",
  objectFit: "cover",
  width: width ? `${width}` : "100%",
  height: height && `${height}`,
  gridArea: "1 / 1 / 2 / 2",
  display: isMobile ? "block" : 'none',
  [theme.breakpoint.md()]: {
    display: isMobile ? "none" : 'block',
  },
}));

export const Overlay = styled.div(({ theme, center = true, height, isMobile }) => ({
  display: isMobile ? "flex" : 'none',
  position: "relative",
  flexDirection: "row",
  alignItems: "center",
  margin: `0 ${center ? "auto" : "12px"}`,
  gridArea: "1 / 1 / 2 / 2",
  height: height ? height : "unset",

  [theme.breakpoint.md()]: {
    display: isMobile ? "none" : 'flex',
    margin: `0 ${center ? "auto" : "24px"}`,
  },

  [theme.breakpoint.lg()]: {
    margin: `0 ${center ? "auto" : "56px"}`,
  },

  [theme.breakpoint.xl()]: {
    margin: `0 ${center ? "auto" : "0 0 calc((100% - 1560px)/2)"}`,
  },

  "@media (min-width: 1200px) and (max-width: 1600px)": {
    margin: `0 ${center ? "auto" : "112px"}`,
  },
}));

export const Content = styled.div({
  gridArea: "2 / 1 / 3 / 2",
});