import { useEffect } from "react";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";

import Banner from "components/HomeBanner";
import HomeContent from "components/HomeContent";
import HomeContact from "components/HomeContact";
import HoldingGroup from "components/Holding/HoldingGroup";


const Home = () => {
  const history = useHistory();
  useEffect(() => {
    document.title = "CNP Seguros Holding Brasil";
    history.replace("/");
  }, [history]);
  
  
  return (
    <article>
      <Helmet>
        <title>CNP Seguros Holding Brasil</title>
        <meta name="description" content="A CNP Seguros Holding Brasil atua no mercado segurador brasileiro há mais de 21 anos, ofertando produtos aderentes a todas as jornadas de vida." />
        <meta name="keywords" content="CNP Seguros Holding Brasil, CNP Seguros, CNP Holding Brasil, CNP Holding, CNP Brasil, Nossa História, seguradora, segurado, parceria, CNP" />
      </Helmet>
      <Banner />
      <HomeContent />
      <HoldingGroup/>
      <HomeContact />
    </article>
  );
};

export default Home;
