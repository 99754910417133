import { useState, useEffect } from "react";
import loadWebpImage from "utils/loadWebpImage";

import Banner from "components/common/Banner";

import Overlay from "./Overlay";
import OverlayMobile from "./OverlayMobile";

import HomeImage from "assets/images/banner_home.webp";
import HomeImageFallback from "assets/images/banner_home.png";

import HomeImageMobile from "assets/images/banner_home_mobile.webp";
import HomeImageMobileFallback from "assets/images/banner_home_mobile.png";

const HomeBanner = () => {
  const [banner, setBanner] = useState('')
  const [bannerMobile, setBannerMobile] = useState('')

  useEffect(() => {
    loadWebpImage(HomeImage, HomeImageFallback, setBanner)
    loadWebpImage(HomeImageMobile, HomeImageMobileFallback, setBannerMobile)
  }, [])

  return (
      <section role="banner">
          <Banner background={banner} width="100vw" height="45vw">
            <Banner.Overlay center={false}>
              <Overlay />
            </Banner.Overlay>
          </Banner>
    
          <Banner
            mobileBackground={bannerMobile}
            width="100vw"
            height="130vw"
            isMobile
          >
            <Banner.Overlay center={false} isMobile>
              <OverlayMobile />
            </Banner.Overlay>
          </Banner>
      </section>
    );
};
export default HomeBanner;
