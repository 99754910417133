import { useEffect, useState } from "react";
import * as S from "./HoldingGroup.style";

import LogoYouse from "assets/images/logo_youse.webp";
import LogoCaixa from "assets/images/logo_caixa_seguradora.webp";

import LogoYouseFallback from "assets/images/logo_youse.png";
import LogoCaixaFallback from "assets/images/logo_caixa_seguradora.png";
import loadWebpImage from "utils/loadWebpImage";

const HoldingGroup = () => {
  const [logoYouse, setLogoYouse] = useState("");
  const [logoCaixa, setLogoCaixa] = useState("");

  useEffect(() => {
    loadWebpImage(LogoYouse, LogoYouseFallback, setLogoYouse);
    loadWebpImage(LogoCaixa, LogoCaixaFallback, setLogoCaixa);
  }, []);

  const GroupCompany = [
    {
      logo: logoYouse,
      alt: "Youse",
      content:
        "100% digital, a Youse revolucionou o jeito de vender seguro no País. Disruptiva, inovadora e com 50% da fatia do mercado, tornou-se um case de sucesso quando o assunto é experiência do cliente.",
      url: "https://www.youse.com.br/",
    },
    {
      logo: logoCaixa,
      alt: "Caixa Seguradora",
      content: "Surgiu a partir da união entre duas gigantes a CNP Assurances e a Caixa Econômica Federal.  Juntas, fazem a gestão de Seguro Habitacional e Residencial comercializados até 2021.",
      url: "https://www.caixaseguradora.com.br/Paginas/home-geral.aspx",
    },
  ];

  const uri = window.location.href.split("/");

  const titleEnterprise = (
    <>
    Conheça as
    <br />
    nossas empresas
    </>
    );
  const titleHome = (
    <>
    Nossas empresas
    </>
  );

  const titleSection = () => {
    if(!uri.includes("empresas-grupo")){
      return titleHome
    }else{
      return titleEnterprise
    }
  }

  return (
    <S.mainContainer>
      <S.Container>
        <S.Title variant="headline-02" color="primary">
          {titleSection()}
        </S.Title>
        {GroupCompany.map((company, index) => (
          <S.CardContainer key={index}>
            <S.CardContent>
              <S.CardImage width="auto" height="100%" src={company.logo} alt={company.alt} />
            </S.CardContent>
            <S.CardContent>
              <S.CardParagraph>{company.content}</S.CardParagraph>
              <S.ActionButton
                onClick={() => company.url && window.open(company.url, "_blank")}
                alt={`Saber mais sobre a ${company.alt}`}
              >
                Saiba mais
              </S.ActionButton>
            </S.CardContent>
          </S.CardContainer>
        ))}
      </S.Container>
    </S.mainContainer>
  );
};

export default HoldingGroup;
