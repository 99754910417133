import styled from "styled-components/macro";
import { Button, Text } from "@gcs/design-system/react";

export const ContactContainer = styled.section(({ theme }) => ({
  display: "block",
  flexDirection: "column",
  padding: "16px 0",

  [theme.breakpoint.lg()]: {
    padding: "112px 20px",
  },

  "@media (min-width: 1320px)": {
    padding: "112px calc((100% - 1260px)/2)",
  },
}));

export const SectionTitle = styled(Text)(({ theme }) => ({
  marginBottom: "10px",
  color: theme.color.brandPrimary.light,
  lineHeight: "150%",
  fontWeight: "700",
  fontSize: "32px",
  paddingLeft: "26px",

  [theme.breakpoint.lg()]: {
    marginBottom: "120px",
    fontSize: "40px",
    paddingLeft: "0",
  },
}));

export const Initiative = styled.div({
  height: "100%",
  width: "100%",
  outline: "none",
  position: "relative",
});

export const InitiativeImage = styled.img(({ theme }) => ({
  width: "100%",
  zIndex: "1",

  [theme.breakpoint.lg()]: {
    width: "350px",
    objectFit: "scale-down",
    position: "absolute",
    top: "-50px",
  },

  [theme.breakpoint.xl()]: {
    width: "auto",
  },
}));

export const InitiativeContent = styled.div(({ theme }) => ({
  padding: "36px 20px",
  backgroundColor: theme.color.brandPrimary.light,
  marginTop: "-5px",

  [theme.breakpoint.lg()]: {
    margin: "50px 0 0 100px",
    paddingLeft: "280px",
    paddingTop: "20px",
    minHeight: "230px",
    height: "100%",
  },

  [theme.breakpoint.xl()]: {
    margin: "50px 0 0 150px",
    paddingLeft: "480px",
    paddingTop: "50px",
    minHeight: "400px",
  },
}));

export const InitiativeContentTitle = styled(Text)(({ theme }) => ({
  marginBottom: "10px",
  color: "white",
  lineHeight: "150%",
  fontWeight: "700",
  fontSize: "24px",

  [theme.breakpoint.lg()]: {
    paddingRight: "50px",
    marginBottom: "24px",
    fontSize: "28px",
  },
}));

export const InitiativeContentDescription = styled(Text)(({ theme }) => ({
  color: theme.color.neutral["08"],
  lineHeight: "160%",
  fontSize: "18px",

  [theme.breakpoint.lg()]: {
    fontSize: "20px",
    maxWidth: "480px",
  },
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(224.34deg, #66D2CC -50.46%, #0084AA 62.35%);",
  borderRadius: "8px",
  marginTop: "24px",
  fontSize: "14px",
  fontWeight: "700",
  width: "200px",
  padding: "16px 36px",

  "&:active": {
    background: theme.color.brandTertiary.medium,
  },

  [theme.breakpoint.lg()]: {
    fontSize: "16px",
  }
}));
