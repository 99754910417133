import * as S from "./Banner.style";

const Banner = ({
  background,
  mobileBackground,
  width,
  height,
  children,
  isMobile,
  alt
}) => {
  return (
    <S.Banner>
      <S.Background
        src={isMobile ? mobileBackground : background}
        width={width}
        height={height}
        alt={alt || "banner"}
        isMobile={isMobile}
      />
      {children}
    </S.Banner>
  );
};

Banner.Overlay = S.Overlay;
Banner.Content = S.Content;

export default Banner;
