import { useState, useEffect, useContext } from "react";
import { ReactComponent as NextImg } from "assets/svgs/next.svg";
import loadWebpImage from "utils/loadWebpImage";
import AppContext from "context/AppContext";

import * as S from "./HomeContent.style";

import WhoWeAreImg from "assets/images/card_who_we_are.webp";
import OurHistoryImg from "assets/images/card_our_history.webp";
import InstituteImg from "assets/images/card_institute.webp";

import WhoWeAreImgFallback from "assets/images/card_who_we_are.png";
import OurHistoryImgFallback from "assets/images/card_our_history.png";
import InstituteImgFallback from "assets/images/card_institute.png";

const HomeContent = () => {
  const { setContext } = useContext(AppContext);

  const [whoWeAre, setWhoWeAre] = useState('')
  const [ourHistory, setOurHistory] = useState('')
  const [institute, setInstitute] = useState('')

  useEffect(() => {
    loadWebpImage(WhoWeAreImg, WhoWeAreImgFallback, setWhoWeAre) 
    loadWebpImage(OurHistoryImg, OurHistoryImgFallback, setOurHistory) 
    loadWebpImage(InstituteImg, InstituteImgFallback, setInstitute) 
  }, [])

  return (
    <S.HomeContent>
      <S.HomeInfo>
        <S.Title variant="headline-02" color="primary">
          Conheça a nossa história
        </S.Title>
        <S.CardContainer role="list">
          <S.HomeCard
            role="list-item"
            onClick={() => setContext({ navigateTo: "/quem-somos" })}
          >
            <S.CardImage
              src={whoWeAre}
              width="280px"
              height="194px"
              alt=""
            />
            <S.CardFooter variant="body01-sm" color="primary">
              Quem é a CNP Assurances <NextImg />
            </S.CardFooter>
          </S.HomeCard>

          <S.HomeCard
            role="list-item"
            onClick={() => setContext({ navigateTo: "/nossa-historia" })}
          >
            <S.CardImage
              src={ourHistory}
              width="280px"
              height="194px"
              alt=""
            />
            <S.CardFooter variant="body01-sm" color="primary">
              Nossa história <NextImg />
            </S.CardFooter>
          </S.HomeCard>

          <S.HomeCard
            role="list-item"
            onClick={() => setContext({ navigateTo: "/instituto" })}
          >
            <S.CardImage
              src={institute}
              width="280px"
              height="194px"
              alt=""
            />
            <S.CardFooter variant="body01-sm" color="primary">
              Instituto CNP Brasil <NextImg />
            </S.CardFooter>
          </S.HomeCard>
        </S.CardContainer>
      </S.HomeInfo>
    </S.HomeContent>
  );
};

export default HomeContent;
