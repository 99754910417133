import styled from "styled-components/macro";
import { Text } from "@gcs/design-system/react";

const fitContent = "fit-content";

export const MainInfoFactory = ({ theme }) => ({
  backgroundColor: theme.color.brandPrimary.light,
  lineHeight: "150%",
  width: fitContent,
  margin: "0 0 8px",
  fontWeight: "700",
  padding: "8px 16px",
  fontSize: "32px",
  display: "block",

  "@media (max-width: 375px)": {
    fontSize: "20px",
  },

  [theme.breakpoint.xs()]: {
    fontSize: "20px",
  },

  [theme.breakpoint.md()]: {
    marginLeft: "10px",
  },

  [theme.breakpoint.xl()]: {
    margin: "0 20px 8px",
    padding: "8px 16px",
    fontSize: "40px",
  },
});

export const SecondaryInfoFactory = ({ theme }) => ({
  backgroundColor: theme.color.brandSecondary.light,
  padding: "8px 16px",
  width: fitContent,
  lineHeight: "150%",
  fontWeight: "700",
  margin: "0 0 8px",
  fontSize: "20px",
  display: "block",

  "@media (max-width: 375px)": {
    fontSize: "16px",
  },

  [theme.breakpoint.xs()]: {
    fontSize: "16px",
  },

  [theme.breakpoint.md()]: {
    marginLeft: "10px",
  },

  [theme.breakpoint.xl()]: {
    marginTop: "0",
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "20px",
    padding: "8px 16px",
    fontSize: "24px",
  },
});

export const Overlay = styled.div(({ theme }) => ({
  top: "100px",
  marginTop: "auto",
  marginBottom: "32px",
  

  [theme.breakpoint.lg()]: {
    top: "unset",
    marginTop: "100px",
  },

  [theme.breakpoint.xl()]: {
    right: 0,
    marginRight: "150px",
    marginTop: "300px",
  },
}));

export const TextBanner = styled(Text)(({ theme }) => ({}));

export const MainInfo = styled.span(({ theme }) => ({
  ...MainInfoFactory({theme})
}));

export const SecondaryInfo = styled.span(({ theme }) => ({
  ...SecondaryInfoFactory({theme})
}));

export const BannerIndicator = styled.div(({ theme }) => ({
  position: "absolute",
  height: "calc(50% + 30px)",
  background: "none",
  border: "2px solid",
  borderColor: theme.color.brandSecondary.light,
  borderTop: "none",
  borderRight: "none",
  borderBottomLeftRadius: "8px",
  display: "none",

  [theme.breakpoint.xl()]: {
    marginTop:"0px",
    display: "block",
    height: "calc(50% - 84px)",
    transform: "translate(0, -72px)",
    width: "75px", // vcalc((100vw - 790px)/2 - 490px)
  },
}));

export const Disclaimer = styled(Text)(({ theme }) => ({
  ...SecondaryInfoFactory({theme}), 
  color: "#fff", 
  backgroundColor: "transparent",
  fontSize: "20px !important",
  cursor: "pointer",

  [theme.breakpoint.lg()]: {
    fontSize: "20px !important",
  }
}));

export const ActionButton = styled.a(({ theme }) => ({
  background:  theme.color.neutral["08"],
  borderRadius: "8px",
  marginTop: "24px",
  fontSize: "16px",
  fontWeight: 700,
  color: theme.color.brandPrimary.light,
  padding: "16px 36px",
  display: "inline-block",
  textDecoration: "none",
  

  [theme.breakpoint.md()]: {
    marginLeft: "10px",
  },

  [theme.breakpoint.xl()]: {
    margin: "24px 20px 8px",
  },

  "&:active": {
    background: theme.color.brandPrimary.light,
    color:  theme.color.neutral["08"],
  },

  "&:hover": {
    background: theme.color.brandPrimary.light,
    color:  theme.color.neutral["08"],
  }
}));
