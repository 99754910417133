import React, { useState, useEffect, useContext } from "react";
import * as S from "./HomeContact.style";
import ContactHomeImg from "assets/images/contact_home.webp";
import ContactHomePNGImg from "assets/images/contact_home.png";
import loadWebpImage from "utils/loadWebpImage";
import AppContext from "context/AppContext";

const HomeContact = () => {
  const { setContext } = useContext(AppContext);
  const [contactImage, setContactImage] = useState();

  useEffect(() => {
    loadWebpImage(ContactHomeImg, ContactHomePNGImg, setContactImage);
  }, []);

  return (
    <S.ContactContainer>
      <S.SectionTitle variant="headline-02" fontSize="40px"  color="primary">
        Encontre a CNP Seguros Holding Brasil
      </S.SectionTitle>
      <S.Initiative>
        <S.InitiativeImage
          width="auto"
          height="auto"
          src={contactImage}
        />
        <S.InitiativeContent>
          <S.InitiativeContentTitle variant="headline-03" fontSize="24px">
            Onde estamos
          </S.InitiativeContentTitle>
          <S.InitiativeContentDescription variant="body02-sm" color="primary">
            SHN Quadra 1, Conjunto A, Bloco E <br />
            Asa Norte, Brasília/DF <br />
            CEP: 70701-050
          </S.InitiativeContentDescription>
          <S.ActionButton
            alt="Entre em contato com a C N P"
            onClick={() => setContext({ navigateTo: "/fale-conosco" })}
          >
            Fale conosco
          </S.ActionButton>
        </S.InitiativeContent>
      </S.Initiative>
    </S.ContactContainer>
  );
};

export default HomeContact;
