import { Text } from "@gcs/design-system/react";
import Card from "components/common/Card";
import styled from "styled-components/macro";

export const HomeContent = styled.section(({ theme }) => ({
  margin: "30px 12px",

  [theme.breakpoint.md()]: {
    margin: "30px 24px",
  },

  [theme.breakpoint.lg()]: {
    margin: "30px 56px",
  },

  [theme.breakpoint.xl()]: {
    margin: "30px 8px 16px 220px",
  },

  "@media(min-width: 1401px)": {
    margin: "30px 8px 16px 320px",
  }
}));

export const HomeInfo = styled.div({});

export const Title = styled(Text)(({ theme }) => ({
  textAlign: "center",
  marginBottom: "40px",
  fontSize: "24px",
  fontWeight: "500",
  lineHeight: "150%",

  [theme.breakpoint.lg()] : {
    fontSize: "28px",
    textAlign: "left",
  }
}));

export const CardContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flexWrap: "wrap",

  "@media(min-width: 1016px)": {
    flexDirection: "row",
    maxWidth: "100%",
  },
}));

export const HomeCard = styled(Card)(({ theme }) => ({
  maxWidth: "280px",
  marginBottom: "20px",
  cursor: "pointer",

  [theme.breakpoint.lg()]: {
    marginRight: "32px",

    "&:last-child": {
      marginRight: "0",
    },
  },

  [theme.breakpoint.xl()]: {
    maxWidth: "370px",
  },

  "@media(min-width: 1200px) and (max-width: 1232px)": {
    marginRight: "16px",
  },
}));

export const CardImage = styled.img({
  objectFit: "cover",
});

export const CardFooter = styled(Text)(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.color.neutral["07"],
  padding: "18px",
  paddingLeft: "16px",

  svg: {
    float: "right",
    fontSize: "18px",
  },
}));
