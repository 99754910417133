import styled from "styled-components/macro";
import { Text, Button } from "@gcs/design-system/react";

export const mainContainer = styled.section(({ theme }) => ({
  borderBottom: `1px solid ${theme.color.neutral["04"]}`,
  [theme.breakpoint.md()]: {
    margin: "0 24px",
  },

  [theme.breakpoint.lg()]: {
    margin: "0 56px",
  },

  [theme.breakpoint.xl()]: {
    margin: "0px 64px 0px 264px",
  }}))

export const Container = styled.div(({ theme }) => ({
  padding: "44px 16px",

  
}));

export const Title = styled(Text)(({ theme }) => ({
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "150%",
    marginBottom: "40px",
  
    [theme.breakpoint.xl()]: {
      textAlign: "left",
      fontSize: "40px",
    },
}));

export const CardContainer = styled.div(({ theme }) => ({
  padding: "32px",
  borderBottom: "1px solid #E3E0E2",

  [theme.breakpoint.lg()]: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoint.lg()]: {
      ":last-child":{
        borderBottom: "0",
      }
    }
  }
}));

export const CardImage = styled.img(({ theme }) => ({
  marginBottom: "32px",
}));

export const CardContent = styled.div(({ theme }) => ({
  minWidth: "300px"
}));

export const CardParagraph = styled(Text)(({ theme }) => ({
    marginBottom: "32px",
    fontWeight: "500",
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(224.34deg, #66D2CC -50.46%, #0084AA 62.35%)',
  boxShadow: '0px -2px 2px rgba(45, 41, 38, 0.05), 0px 8px 12px rgba(45, 41, 38, 0.16)',
  borderRadius: "8px",
  fontSize: "14px",
  width: "200px",
  marginBottom: "32px",

  "&:active": {
    background: theme.color.brandTertiary.medium,
  },
  
  [theme.breakpoint.lg()]: {
    fontSize: "18px"
  }
}));

